import Cookies from 'js-cookie';

function getScriptURL() {
  switch (window.location.host) {
    case process.env.RO_GATEWAY_DOMAIN_PROD:
      if (window.location.pathname.startsWith('/landing/f')) {
        return {
          analytics: process.env.RO_FRAMER_URL_PROD!,
          styles: process.env.RO_STYLES_URL_PROD!,
        };
      } else if (window.location.pathname.startsWith('/landing/u')) {
        return {
          analytics: process.env.RO_UNBOUNCE_URL_PROD!,
          styles: process.env.RO_STYLES_URL_PROD!,
        };
      } else {
        return {
          analytics: process.env.RO_WEBFLOW_URL_PROD!,
          styles: process.env.RO_STYLES_URL_PROD!,
        };
      }
    case process.env.RO_GATEWAY_DOMAIN_DEV:
      if (window.location.pathname.startsWith('/landing/f')) {
        return {
          analytics: process.env.RO_FRAMER_URL_DEV!,
          styles: process.env.RO_STYLES_URL_DEV!,
        };
      } else if (window.location.pathname.startsWith('/landing/u')) {
        return {
          analytics: process.env.RO_UNBOUNCE_URL_DEV!,
          styles: process.env.RO_STYLES_URL_DEV!,
        };
      } else {
        return {
          analytics: process.env.RO_WEBFLOW_URL_DEV!,
          styles: process.env.RO_STYLES_URL_DEV!,
        };
      }
    case process.env.RO_GATEWAY_DOMAIN_STAGING:
      if (window.location.pathname.startsWith('/landing/f')) {
        return {
          analytics: process.env.RO_FRAMER_URL_STAGING!,
          styles: process.env.RO_STYLES_URL_STAGING!,
        };
      } else if (window.location.pathname.startsWith('/landing/u')) {
        return {
          analytics: process.env.RO_UNBOUNCE_URL_STAGING!,
          styles: process.env.RO_STYLES_URL_STAGING!,
        };
      } else {
        return {
          analytics: process.env.RO_WEBFLOW_URL_STAGING!,
          styles: process.env.RO_STYLES_URL_STAGING!,
        };
      }
    case 'welcome.remento.co':
      return {
        analytics: process.env.RO_FRAMER_URL_PROD!,
        styles: process.env.RO_STYLES_URL_PROD!,
      };
    case 'landing.remento.co':
      return {
        analytics: process.env.RO_UNBOUNCE_URL_PROD!,
        styles: process.env.RO_STYLES_URL_PROD!,
      };
    default:
      return {
        analytics: process.env.RO_WEBFLOW_URL_PROD!,
        styles: process.env.RO_WEBFLOW_URL_PROD!,
      };
  }
}

function getCacheBusterURL(urls: { analytics: string; styles: string }, cacheBusterValue: null | string) {
  if (cacheBusterValue == null) {
    return urls;
  } else {
    return {
      analytics: `${urls.analytics}?ro_cdn_cache_buster=${cacheBusterValue}`,
      styles: `${urls.styles}?ro_cdn_cache_buster=${cacheBusterValue}`,
    };
  }
}

(function () {
  const queryParams = new URLSearchParams(window.location.search);
  const cacheBusterValue = queryParams.get('ro_cdn_cache_buster') == null ? null : `${Date.now()}`;
  if (cacheBusterValue != null) {
    Cookies.set('ro_cdn_cache_buster', cacheBusterValue, { domain: '.remento.co' });
  } else if (Cookies.get('ro_cdn_cache_buster') != null) {
    Cookies.remove('ro_cdn_cache_buster', { domain: '.remento.co' });
  }
  const scriptUrls = getScriptURL();
  const analyticsUrlOverride = queryParams.get('ro_analytics_url_override');
  if (analyticsUrlOverride != null) {
    scriptUrls.analytics = analyticsUrlOverride;
  }
  const urls = getCacheBusterURL(scriptUrls, cacheBusterValue);

  const $head = document.querySelector('head') as HTMLHeadElement;
  const $script = document.createElement('script');
  $script.async = true;
  $script.src = urls.analytics;
  $head.appendChild($script);

  if (queryParams.get('ro_debug') != null) {
    const $styles = document.createElement('link');
    $styles.rel = 'stylesheet';
    $styles.href = urls.styles;
    $head.appendChild($styles);
  }
})();
